* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

.page_loader_container {
	width: 100%;
	height: 100vh;
	background-color: white;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}

.image_wrapper {
	position: relative;
	width: 150px;
	height: 150px;
	border-radius: 50%;
	isolation: isolate;
}

.main_logo {
	position: absolute;
	top: 50%;
	left: 50%;
	margin-top: -40px;
	margin-left: -40px;
	width: 80px;
	height: 80px;
	z-index: 2;
}

.loader {
	position: absolute;
	top: 50%;
	left: 50%;
	margin-top: -60px;
	margin-left: -60px;
	width: 120px;
	aspect-ratio: 1;
	--c: no-repeat radial-gradient(farthest-side, #009dd9 92%, #0000);
	background: var(--c) 50% 0, var(--c) 50% 100%, var(--c) 100% 50%,
		var(--c) 0 50%;
	background-size: 10px 10px;
	animation: l18 1s infinite;
}
.loader::before {
	content: "";
	position: absolute;
	inset: 0;
	margin: 3px;
	background: repeating-conic-gradient(#0000 0 35deg, #009dd9 0 90deg);
	-webkit-mask: radial-gradient(
		farthest-side,
		#0000 calc(100% - 3px),
		#000 0
	);
	border-radius: 50%;
}
@keyframes l18 {
	100% {
		transform: rotate(0.5turn);
	}
}
